import api from '../util/request.js'

export const getReportMacro = async ({dateIni, dateEnd, typeReport}) => {
  const API_URL_REPORT = {
    0: 'reportes/reporte-gerencial',
    1: 'reportes/reporte-gerencial-sucursales',
  }
  try {
    const { data } = await api.get(API_URL_REPORT[typeReport] ?? '', {
      responseType: 'blob',
      params: {
        fecha_inicial: dateIni,
        fecha_final: dateEnd,
      },
    })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getReportGeneral = async ({dateIni, dateEnd}) => {
  try {
    const { data } = await api.get('reportes/reporte-gerencial-general', {
      responseType: 'blob',
      params: {
        fecha_inicial: dateIni,
        fecha_final: dateEnd,
      },
    })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const generateReports = async ({dateIni, dateEnd, report}) => {
  try {
    const { data } = await api.get(`reportes/${report.replaceAll(' ', '-')}`, {
      responseType: 'blob',
      params: {
        fecha_inicial: dateIni,
        fecha_final: dateEnd,
      },
    })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getReports = async () => {
  try {
    const { data } = await api.get('reportes')
    return data && data?.length > 0
      ? data.map(item => {
          return item.split('-').join(' ')
        })
      : []
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getReports2 = async () => {
  try {
    const { data } = await api.get('administracion/urlarchivocarga')
    return data && data?.length > 0
      ? data.map(item => {
          return item.split('-').join(' ').toUpperCase()
        })
      : []
  } catch (error) {
    return Promise.reject(error)
  }
}

<template>
  <v-container id="vendedores" fluid tag="section" class="pa-1">
    <main-header />
    <v-row>
      <v-col cols="12" class="pt-5">
        <v-card tile>
          <v-card-text class="pa-0 ma-0">
            <v-row class="py-1 px-4">
              <v-col cols="12" sm="8" md="6" class="d-flex align-center">
                <v-text-field
                  v-model="searchVen"
                  placeholder="Buscar vendedor"
                  dense
                  hide-details
                  class="theme-custom"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="secondary" class="pt-1">mdi-magnify</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="6"
                class="d-flex align-center"
                :class="{
                  'justify-end': $vuetify.breakpoint.smAndUp,
                  'justify-center': $vuetify.breakpoint.xsOnly
                }"
              >
                <columns-views :columns="headersVen" />
                <!-- <v-divider
                  class="mr-2"
                  vertical
                  v-if="$vuetify.breakpoint.xsOnly"
                ></v-divider>
                <v-btn
                  small
                  color="primary"
                  depressed
                  @click="filtersVen = !filtersVen"
                >
                  <v-icon left>mdi-tune</v-icon>
                  Filtros
                </v-btn> -->
              </v-col>
            </v-row>
            <v-divider class="mt-3" />
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pa-0 ma-0">
                <data-vendedores
                  :vendedores="vendedores"
                  :search="searchVen"
                  :headers="headersVen"
                  :loading="loadingVen"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Vendedores',
  components: {
    DataVendedores: () =>
      import(
        /* webpackChunkName: "data-Vendedores" */
        './Components/DataVendedores.vue'
      ),
    columnsViews: () =>
      import(
        /* webpackChunkName: "data-clients" */
        '@/widgets/ColumnsViews'
      )
  },
  data: () => ({
    searchVen: '',
    filtersVen: false,
    loadingVen: false,
    vendedores: [],
    headersVen: [
      {
        text: '#',
        value: 'co_ven',
        class: 'header-data',
        width: '60',
        active: true
      },
      {
        text: 'Nombre',
        align: 'start',
        sortable: false,
        value: 'ven_des',
        class: 'header-data',
        active: true
      },
      {
        text: 'Portafolio',
        align: 'center',
        sortable: false,
        value: 'portafolios',
        class: 'header-data',
        active: true
      },
      { text: '', value: 'actions', class: 'header-data', active: true }
    ]
  }),
  created() {
    this.getVendedores()
  },
  methods: {
    async getVendedores() {
      try {
        this.loadingVen = true
        const { data } = await this.$api.get('vendedores')

        this.vendedores = data.data

        this.vendedores.map((vendedor) => {
          if (!vendedor.portafolios) {
            console.log(vendedor)
            vendedor.portafolios = []
          }

          vendedor.portafolios =
            vendedor.portafolios.length > 0
              ? JSON.parse(vendedor.portafolios)
              : ['S/P']

          return vendedor
        })
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Vendedores.',
          'error'
        )
        console.log(error)
      }
      this.loadingVen = false
    }
  }
}
</script>

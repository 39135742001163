var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-1",attrs:{"id":"reporte-macro","fluid":"","tag":"section"}},[_c('main-header'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"py-6"},[_c('v-row',{staticClass:"pb-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list-item',{staticClass:"pl-0"},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"icono","size":"26"}},[_vm._v("mdi-file-chart-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text font-weight-bold text-h4"},[_vm._v(" Obtener Reporte ")]),_c('v-list-item-subtitle',{staticClass:"blue-grey--text"},[_vm._v(" Seleccione el rango de fecha para descargar los datos correspondiente. ")])],1)],1)],1)],1),_c('validation-observer',{ref:"REPORT_RANGO",attrs:{"tag":"div"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mb-0",attrs:{"cols":"11","sm":"8","md":"5"}},[_c('validation-provider',{attrs:{"name":"Tipo de Reporte","vid":"report","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label-form',{attrs:{"text":"Tipo de Reporte","required":""}}),_c('v-select',{attrs:{"items":_vm.dataReports,"dense":"","outlined":"","clearable":"","error-messages":errors[0],"menu-props":{ bottom: true, offsetY: true },"loading":_vm.loadReport,"disabled":_vm.loadReport},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","size":"20","color":"icono"}},[_vm._v("mdi-file-download-outline")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase font-weight-medium"},[_vm._v(_vm._s(item))])]}}],null,true),model:{value:(_vm.report),callback:function ($$v) {_vm.report=$$v},expression:"report"}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11","sm":"8","md":"5"}},[_c('div',{staticClass:"blue-grey--text text--darken-3 pb-1"},[_c('v-icon',{staticClass:"mr-1 mt-n1",attrs:{"small":"","color":"blue-grey"}},[_vm._v(" mdi-calendar-month ")]),_vm._v(" Fecha Inicio "),_c('span',{staticClass:"secondary--text font-weight-bold",domProps:{"textContent":_vm._s('*')}})],1),_c('validation-provider',{attrs:{"name":"Fecha Inicio","vid":"rango.inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fecha-input',{attrs:{"color":errors[0] ? 'red' : 'line-input',"outlined":"","max":_vm.maxDate,"error":errors[0],"nudge-left":10,"width-input":"100%"},model:{value:(_vm.rango.inicio),callback:function ($$v) {_vm.$set(_vm.rango, "inicio", $$v)},expression:"rango.inicio"}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11","sm":"8","md":"5"}},[_c('div',{staticClass:"blue-grey--text text--darken-3 pb-1"},[_c('v-icon',{staticClass:"mr-1 mt-n1",attrs:{"small":"","color":"blue-grey"}},[_vm._v(" mdi-calendar-month ")]),_vm._v(" Fecha Fin "),_c('span',{staticClass:"secondary--text font-weight-bold",domProps:{"textContent":_vm._s('*')}})],1),_c('validation-provider',{attrs:{"name":"Fecha Fin","vid":"fin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fecha-input',{attrs:{"color":errors[0] ? 'red' : 'line-input',"outlined":"","max":_vm.maxDate,"error":errors[0],"nudge-left":10,"width-input":"100%"},model:{value:(_vm.rango.fin),callback:function ($$v) {_vm.$set(_vm.rango, "fin", $$v)},expression:"rango.fin"}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11","sm":"8","md":"5"}},[_c('v-btn',{attrs:{"dark":"","depressed":"","color":"icono","large":"","block":"","loading":_vm.loading},on:{"click":_vm.downloadReport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" descargar reporte")],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }